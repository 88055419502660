import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styles from "../components/header.module.css"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import classnames from "classnames";
import { window } from 'browser-monads';
import disableScroll from 'disable-scroll';

class Header extends React.Component {
constructor(props){
  super(props)
  this.state = {
    isActive : false,
    prevScrollpos: window.pageYOffset,
      visible: true
  }
}

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;
    if (currentScrollPos > 300){
      this.setState({
        prevScrollpos: currentScrollPos,
        visible
      });
    }
  
  };

toggleClass = () => this.setState({
  isActive : !this.state.isActive
})
scrollPreventionOnActiveNav = () => {
  if(this.state.isActive){
    disableScroll.on()
  } else{
    disableScroll.off()
  }
}
render() {
  // this.scrollPreventionOnActiveNav();
    return ( 
      <div>
      <header className={`${styles.header} ${classnames("header", {
        "stick--nav": !this.state.visible
      })}`}>
      
        
        <div className={`${styles.hide_on_mobile} ${styles.nav_wrap} row`}>
        <div className={styles.logo_section} >
        <Link to="/">
        <span className={`${styles.logo} sticky--logo`}></span>
        </Link>
       
        </div>
        <div className={`${styles.main_nav} main_nav_wrap`} >
            <div className={`${styles.top_section_nav} hide_on_sticky`}>
                <ul>
                  <li>
                  <Link to="/investorAndMediaRelations">Home</Link>
                  </li>
                  <li><Link to="">News</Link></li>
                  <li><Link to="">Contact us</Link></li>
                  <li>Search</li>
                </ul>
            </div>
            <ul style={{float: 'right'}}>
            
              <li className={styles.primary_dropdown}>About us
              <div className={styles.dropdown_wrap}>
                <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-12"}>
                    {/* <img src="https://images.ctfassets.net/pj3y55rets5m/1xhM5IWMCdpzyyLgNfGgH3/1eee419988b8ef92c76d1f9c344d0a88/nav_img_1.jpg" alt="news_item"/> */}
                      <div className={styles.first_circle}></div>
                      <div className={styles.second_circle}></div>
                      <div className={styles.third_circle}></div>
                      <div className={styles.fourth_circle}></div>
                      <div className={styles.fifth_circle}></div>
                    <ul >
                    
                    <Link to="/companyProfile"><li className={`${styles.top_nav_btns}`}>Company profile</li></Link>
                    <Link to="/"><li className={`${styles.top_nav_btns}`}>Message from CEO</li></Link>
                    <Link to="/"><li className={`${styles.top_nav_btns}`}>History</li></Link>
                    <Link to="/"><li className={`${styles.top_nav_btns}`}>Board of directors</li></Link>
                    <Link to="/"><li className={`${styles.top_nav_btns}`}>Senior management</li></Link>
                  
           
             
                 </ul>
                      
                    </div>
                   
                    </div>
                </div>
              </div>
                
              </li>
              <li className={styles.primary_dropdown}>Research
              <div className={styles.dropdown_wrap}>
                <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-4"}>
                    {/* <img src="https://images.ctfassets.net/pj3y55rets5m/5uXdDCKzbRKkuiACquSgyj/40ab24d251d3a2362d5c98e17f38dafb/nav_research.jpg" alt="news_item"/> */}
                      <div className={styles.first_circle}></div>
                    <div className={styles.second_circle}></div>
                      <div className={styles.third_circle}></div>
                      <div className={styles.fourth_circle}></div>
                      <div className={styles.fifth_circle}></div>
                      <div style={{position: "absolute", top: 20}}><h5>Clinical trial data access request</h5>
                      <p  style={{position: "relative",zIndex: 99}}>
                      Neurocrine UK is committed to public disclosure and transparency of our clinical study data.
                      </p></div>
                      
                    </div>
                    <div className={"col-12 col-md-8"}>
                    <ul>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>Clinical trial data access request</li></Link>
                  </ul>
                    </div>
                    </div>
                </div>
              </div>
               
              </li>
              <li className={styles.primary_dropdown}>Business
              <div className={styles.dropdown_wrap}>
                <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-5"}>
                    {/* <img src="https://images.ctfassets.net/pj3y55rets5m/TvbuH0LcMGdqvl2LZkxBs/c409f25cfc15f36ea8c1386dfc8c677f/nav_img_3.jpg" alt="news_item"/> */}

                    <div className={styles.first_circle}></div>
                    <div className={styles.second_circle}></div>
                      <div className={styles.third_circle}></div>
                      <div className={styles.fourth_circle}></div>
                      <div className={styles.fifth_circle}></div>
                      <div style={{position: "absolute", top: 20}}>
                        <h5>Partnering</h5>
                      <p  style={{position: "relative",zIndex: 99}}>
                      Partnering and business development is integral to our strategy of building a leading pharmaceutical company. We forge creative partnerships with other innovative companies that advance our business in the field of endocrinology.
                      </p></div>
                      
                    </div>
                    <div className={"col-12 col-md-7"}>
                    <ul>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>Partnering</li></Link>
                   </ul>
                    </div>
                    </div>
                </div>
              </div>
               
              </li>
              <li className={styles.primary_dropdown}>Investors
              <div className={styles.dropdown_wrap}>
                <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-5"}>
                    {/* <img src="https://images.ctfassets.net/pj3y55rets5m/3IXDq0yKd3nUDK3FNXxuS1/f7d4c2fd88de35869218e41fdace50d4/investors.jpg" alt="news_item"/> */}
                      <div className={styles.first_circle}></div>
                    <div className={styles.second_circle}></div>
                      <div className={styles.third_circle}></div>
                      <div className={styles.fourth_circle}></div>
                      <div className={styles.fifth_circle}></div>
                      <div style={{position: "absolute", top: 20}}>
                      <h5>Company information</h5>
                      <p style={{position: "relative",zIndex: 99}}>
                      Neurocrine UK Group PLC is a UK-based specialty pharmaceutical company dedicated to developing high-quality hormone therapeutics to aid lifelong treatment for rare and chronic endocrine conditions, including Congenital Adrenal Hyperplasia, Adrenal Insufficiency, Hypogonadism and Hypothyroidism.
                      </p>
                      </div>
                    </div>
                    <div className={"col-12 col-md-7"}>
                      <div className="row">
                        <div className="col-12 col-md-4">
                          <ul>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>Company information</li></Link>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>Share information</li></Link>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>RNS announcement</li></Link>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>Corporate governance</li></Link>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>Shareholder information</li></Link>
                          </ul>
                        </div>
                        <div className="col-12 col-md-8"><ul>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>Company information</li></Link>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>Share information</li></Link>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>RNS announcement</li></Link>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>Corporate governance</li></Link>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>Shareholder information</li></Link>
                          </ul></div>
                      </div>
                    </div>
                    </div>
                </div>
              </div>
            
              </li>
              <li className={styles.primary_dropdown}>Media
              <div className={styles.dropdown_wrap}>
                <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-4"}>
                    {/* <img src="https://images.ctfassets.net/pj3y55rets5m/6rOSPwf3PZb9q9ts7HdfbJ/2ccb08d9a9ffd1c684525787399b0b78/nav_img_4.jpg" alt="news_item"/> */}
                      <div className={styles.first_circle}></div>
                    <div className={styles.second_circle}></div>
                      <div className={styles.third_circle}></div>
                      <div className={styles.fourth_circle}></div>
                      <div className={styles.fifth_circle}></div>
                      <div style={{position: "absolute", top: 20}}><h5>Media</h5>
                      <p  style={{position: "relative",zIndex: 99}}>
                      Read about all the latest news from Neurocrine UK<Link to="/"> here</Link>.
                      </p></div>
                      
                    </div>
                    <div className={"col-12 col-md-8"}>
                    <ul>
                   <Link to="/"> <li className={`${styles.top_nav_btns}`}>News</li></Link>
                  </ul>
                    </div>
                    </div>
                </div>
              </div>
             
              </li>
              <li className={styles.primary_dropdown}>Careers
              <div className={styles.dropdown_wrap}>
                <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-5"}>
                    {/* <img src="https://images.ctfassets.net/pj3y55rets5m/332k4QiuwUmrFoT6tr8cXD/985a4a2ebd359ac8f0634356888ddc62/join_team.jpg" alt="news_item"/> */}
                      <div className={styles.first_circle}></div>
                    <div className={styles.second_circle}></div>
                      <div className={styles.third_circle}></div>
                      <div className={styles.fourth_circle}></div>
                      <div className={styles.fifth_circle}></div>
                      <div style={{position: "absolute", top: 20}}><h5>Why join us?</h5>
                      <p style={{position: "relative",zIndex: 99}}>
                      Neurocrine UK is a small but rapidly growing specialty pharmaceutical company with a highly integrated and very committed team. We're always looking for talented individuals who share our vision. Visit this section to view our current opportunities.
                      </p></div>
                      
                    </div>
                    <div className={"col-12 col-md-7"}>
                    <ul>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>Why join us?</li></Link>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>Current vacancies</li></Link>
                   </ul>
                    </div>
                    </div>
                </div>
              </div>
              
              </li>
              <li className={styles.primary_dropdown}>Transparency
              <div className={styles.dropdown_wrap}>
                <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-5"}>
                    {/* <img src="https://images.ctfassets.net/pj3y55rets5m/59Y9NE9nytkamvgAklFEXV/d885974115e74595a1312863724c515d/nav_img_6.jpg" alt="news_item"/> */}
                      <div className={styles.first_circle}></div>
                    <div className={styles.second_circle}></div>
                      <div className={styles.third_circle}></div>
                      <div className={styles.fourth_circle}></div>
                      <div className={styles.fifth_circle}></div>
                      <div style={{position: "absolute", top: 20}}><h5>Overview</h5>
                      <p style={{position: "relative",zIndex: 99}}>
                      Neurocrine UK also complies with the EFPIA through this route. Where individual countries have other transparency regulations, Neurocrine UK's declarations for these can be found below.

Neurocrine UK is committed to transparency in clinical trials and publishes the protocols and results of its clinical trials on the website  <a href="">www.clinicaltrials.gov</a>
                      </p></div>
                      
                    </div>
                    <div className={"col-12 col-md-7"}>
                    <ul>
                    <Link to=""><li className={`${styles.top_nav_btns}`}>Overview</li></Link>
                  </ul>
                    </div>
                    </div>
                </div>
              </div>
            
              </li>
            
            </ul>
        </div>
      </div>
    



          {/* start mobile nav */}
      <div className={`${styles.hide_on_desktop} ${styles.mobile_header} ${this.state.isActive ? `${styles.active}` : ""} row`} >
        <div className={"col-6 col-md-3 col-lg-3" } style={{zIndex:'999999999', background:'#fff', padding:'15px'}}>
            <Link to="/">
            <span className={styles.logo}></span>
            </Link>
       
        </div>

        <div className={"col-6 col-md-9 col-lg-9"} style={{zIndex:'999999999', background:'#fff', padding:'20px'}}>
            
        <div className={styles.hamburger} onClick={() => this.toggleClass()} >
          <span className={styles.line}></span>
          <span className={styles.line}></span>
          <span className={styles.line}></span>
        </div>
           
       
        </div>

      <ul className={styles.mobile_nav_item}>
      <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    About us
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <ul>
                  <Link to="/companyProfile"><li>Company profile</li></Link>
                  <Link to="/"> <li>Message from CEO</li></Link>
                    <Link to="/"><li>History</li></Link>
                    <Link to="/BoardOfDirectors"><li>Board of directors</li></Link>
                    <Link to="/"><li>Senior management</li></Link>
                  </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Research
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <ul>
                    <Link to=""><li>Clinical trial data access request</li></Link>
                  </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Business
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <ul>
                    <Link to=""><li>Partnering </li></Link>
                  </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Investors
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <ul>
                    <Link to=""><li>Company information</li></Link>
                    <Link to=""><li>Share information</li></Link>
                    <Link to=""><li>RNS announcement</li></Link>
                    <Link to=""><li>Corporate governance</li></Link>
                    <Link to=""><li>Shareholder information</li></Link>
                    <Link to=""><li>Finacial reports and accounts</li></Link>
                    <Link to=""><li>Advisers</li></Link>
                    <Link to=""><li>Analyst coverage</li></Link>
                    <Link to=""><li>AIM rule 26</li></Link>
                  </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Media
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <ul>
                <Link to="/"> <li>News</li></Link>
                  </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Careers
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <ul>
                    <Link to=""><li>Why join us?</li></Link>
                    <Link to=""><li>Current vacancies</li></Link>
                  </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Transparency
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <ul>
                    <Link to=""><li>Overview</li></Link>
                  </ul>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
      </ul>
      </div>
      {/* end mobile nav */}
    </header>
    </div>
     );
  }
}
 
export default Header;


